


function Divider({text}) {
  return (
    <div className="w-full border-t border-gray-300 lg:mb-24 lg:mt-36 text-center text-3xl font-bold tracking-tight">
      <div className="-mt-5 w-auto flex items-center">
        <div className="bg-white text-hub-dark w-auto mx-auto px-6">{text}</div>
      </div>
    </div>
  );
}

export { Divider };
