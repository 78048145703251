import '../App.css';
import { useState, useRef, useEffect } from "react";
import { Divider } from '../components/Common'

const productTypes = [
  { 
    name: 'Websites', 
    href: '/websites',
    image: '/kanejackson.png',
    description: "Build an exceptional web presence that elevates your brand, engages your audience, and drives sustainable growth." 
  }, { 
    name: 'Applications', 
    href: '/solutions',
    image: 'stockman.png',
    description: `We specialize in creating dynamic and innovative web-apps tailored to meet your unique business needs. \n \
                  Our solutions come with a focus on functionality, user experience, and driving measurable results.`
  },
]

function Products() {
  const [isIntersecting, setIsIntersecting] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting ? "opacity-100" : "opacity-0");
      }
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      console.log("AJSDKAJSHDKA")
    }
  }, [isIntersecting]);


  return (
    <div className="App">

      <div className="relative px-6 mt-28 lg:px-8 shadow-lg">
        <div className={`mx-auto max-w-6xl pb-24 transition-all duration-1000 ${isIntersecting}`} ref={ref}>
          <Divider text={"We can take care of your..."} />
          {productTypes.map((item) => (
            <div 
              key={item.name}
              className="my-16 text-left bg-white flex flex-col-reverse justify-between lg:odd:flex-row lg:even:flex-row-reverse lg:even:justify-end whitespace-pre-line lg:-mx-12">
              <div className="mx-4 my-8 lg:mx-12 lg:my-2">
                <h2 className="text-4xl font-bold tracking-tight">{item.name}</h2>
                <p className="my-6 text-lg leading-8 text-gray-600">{item.description}</p>
                <a
                  href={item.href}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  See more <span aria-hidden="true">→</span>
                </a>
              </div>
              <img
                className="w-full right-0 rounded-xl lg:w-2/5 lg:mx-12 border border-gray-300"
                src={item.image}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default Products;
